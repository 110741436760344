import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { with: String }
  static targets = [ "button", "fileInput" ]

  connect() {
    this.element.dataset['action'] = 'submit->disable#disableForm'
  }

  disableForm(event) {
    event.submitter.setAttribute('disabled', "disabled")

    this.fileInputTargets.forEach(input => {
      input.setAttribute('disabled', "disabled");
    });
  }
}
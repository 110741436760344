import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["template", "gallery", "input", "persisted", "unpersisted", "notice", "gallerySection", "cachedFile", "fileUploads"];
  static classes = ["supportedInput"];

  connect() {
    this.inputTarget.classList.add(...this.supportedInputClasses);
  }

  cachedFileTargetConnected(element) {
    const { multipleFileInputFileNameValue: name, multipleFileInputFileSizeValue: size, multipleFileInputUrlValue: objectURL } = element.dataset;
    this.gallerySectionTarget.classList.remove("hidden");
    this._addFile(this.galleryTarget, name, size, objectURL);
  }

  previewInGallery() {
    if (this.inputTarget.files.length > 0) {
      let inputFiles = this.inputTarget.files;
      this.unpersistedTargets.forEach((n) => n.remove());
      this.notices = {};
      this.noticeTarget.innerHTML = "";

      if (!this._validateFiles(inputFiles)) {
        this._showNoticeForFiles();
        return false;
      }

      Array.from(inputFiles).forEach((file) => {
        if (this._validateFile(file)) {
          this.gallerySectionTarget.classList.remove("hidden");
          const objectURL = URL.createObjectURL(file);
          this._addFile(this.galleryTarget, file.name, file.size, objectURL);
        } else {
          this._showNoticeForFile(file);
          this._removeFileFromList(file);
        }
      });
    }
  }

  removeFile(event) {
    const target = this.unpersistedTargets.find((li) => li.contains(event.currentTarget));
    const index = this.unpersistedTargets.indexOf(target);
    const attachments = this.inputTarget.files;

    this._removeCachedFile(target)
    let fileBuffer = new DataTransfer();

    // append the file list to an array iteratively
    for (let i = 0; i < attachments.length; i++) {
      if (index !== i) fileBuffer.items.add(attachments[i]);
    }

    // Assign buffer to file input
    this.inputTarget.files = fileBuffer.files;
    target.remove();
    if (fileBuffer.files.length === 0 && this.unpersistedTargets.length === 0) {
      this.gallerySectionTarget.classList.add("hidden");
    }
  }

  _removeCachedFile(target) {
    const imgTitle = target.querySelector('span[data-target="name"]').title;
    const fileUploadsContainer = target.closest('div[data-multiple-file-input-target="fileUploads"]')
    const targetInput = fileUploadsContainer.querySelector(`input[id*="${imgTitle}"]`)
    if (targetInput) {
      targetInput.remove();
    }
  }

  removeAll() {
    var fileFieldHTML = this.inputTarget.outerHTML;
    var parent = this.inputTarget.parentElement;
    this.inputTarget.remove();
    parent.insertAdjacentHTML("afterbegin", fileFieldHTML);
    this.unpersistedTargets.forEach((target) => {
      this._removeCachedFile(target);
      target.remove();
    });
    this.cachedTargets.forEach((n) => n.remove());
    this.gallerySectionTarget.classList.add("hidden");
  }

  _validateFiles(files) {
    const validFilesTotalCount = this._validateFilesTotalCount(files);

    return validFilesTotalCount;
  }

  _validateFile(file) {
    const validFileSize = this._validateFileSize(file);
    const validFileType = this._validateFileType(file);

    return validFileSize && validFileType;
  }

  _validateFileType(file) {
    const acceptedTypes = this.inputTarget.accept.replace(/\s/g, "").split(",");
    const validations = acceptedTypes.map((type) => new RegExp(type.replace("*", ".*")));
    const isValid = validations.some((validation) => validation.test(file.type));

    if (isValid) {
      return true;
    } else {
      this.notices[file.name] ||= [];
      this.notices[file.name].push(`"${file.type}" is not an allowed type`);
      return false;
    }
  }

  _validateFileSize(file) {
    const maxFileSize = this.inputTarget.getAttribute("max-file-size");
    if (!maxFileSize) return true;

    const base = 1000;
    const toInt = (value) => parseInt(value, 10);

    const maxFileSizeNatural = maxFileSize.trim();
    let maxFileSizeString = maxFileSizeNatural;
    let maxFileSizeBytes = null;

    if (/MB$/i.test(maxFileSizeString)) {
      maxFileSizeString = maxFileSizeString.replace(/MB$i/, "").trim();
      maxFileSizeBytes = toInt(maxFileSizeString) * base * base;
    } else if (/KB$/i.test(maxFileSizeString)) {
      maxFileSizeString = maxFileSizeString.replace(/KB$i/, "").trim();
      maxFileSizeBytes = toInt(maxFileSizeString) * base;
    } else {
      maxFileSizeBytes = toInt(maxFileSizeString);
    }

    const isValid = maxFileSizeBytes !== null && file.size <= maxFileSizeBytes;
    if (isValid) {
      return true;
    } else {
      this.notices[file.name] ||= [];
      this.notices[file.name].push(
          `${this._humanFileSize(file.size)} is larger than the ${maxFileSizeNatural} allowed`,
      );
      return false;
    }
  }

  _validateFilesTotalCount(files) {
    const maxFilesCount = this.inputTarget.getAttribute("max-files-count");
    if (!maxFilesCount) return true;
    const filesCount = files.length;
    const isValid = filesCount <= maxFilesCount;

    if (isValid) {
      return true;
    } else {
      this.notices["files"] ||= [];
      this.notices["files"].push(`${filesCount} is more than the ${maxFilesCount} allowed`);
      return false;
    }
  }

  _addFile(target, fileName, fileSize, objectURL) {
    const clone = this.templateTarget.content.cloneNode(true);
    const nameElement = clone.querySelector("[data-target='name']");
    const sizeElement = clone.querySelector("[data-target='size']");

    nameElement.textContent = fileName;
    nameElement.title = fileName;
    sizeElement.textContent = this._humanFileSize(fileSize);

    Object.assign(clone.querySelector("img"), {
      src: objectURL,
      alt: fileName,
    });

    target.append(clone);
  }

  _showNoticeForFiles() {
    const li = document.createElement("li");
    li.innerHTML = `
      <code>Please try again&hellip;</code>
      <ul class="list-disc ml-8">
        ${this.notices["files"].map((notice) => `<li>${notice}</li>`).join("")}
      </ul>
    `;
    this.noticeTarget.appendChild(li);
  }

  _showNoticeForFile(file) {
    const li = document.createElement("li");
    li.innerHTML = `
      <code>${file.name}</code>
      <ul class="list-disc ml-8">
        ${this.notices[file.name].map((notice) => `<li>${notice}</li>`).join("")}
      </ul>
    `;
    this.noticeTarget.appendChild(li);
  }

  _humanFileSize(str_size) {
    const base = 1000;
    const size = typeof str_size === 'string' ? parseInt(str_size, 10) : str_size;
    const i = size == 0 ? 0 : Math.floor(Math.log(size) / Math.log(base));
    const suffix = ["B", "KB", "MB", "GB"][i];
    const integer = Math.round(size / Math.pow(base, i));
    return integer + " " + suffix;
  }

  _removeFileFromList(file) {
    const newFiles = Array.from(this.inputTarget.files).filter(f => f !== file);
    const newFileList = new DataTransfer();
    newFiles.forEach(f => newFileList.items.add(f));
    this.inputTarget.files = newFileList.files;
  }
}
